<template>
    <div class="login-box">
        <!-- /.login-logo -->
        <form @submit="submitForm" method="post">
            <div class="login-logo">
                <a href="../../index2.html"><img height="64" src="@/assets/img/logo-white.png"></a>
            </div>
            <div class="card mb-0">
                <div class="card-body login-card-body">
                    <legend style="text-align: center;">Registrasi</legend>
                    <div class="input-group mb-3 mt-5">
                        <input type="text" v-model="form.name" ref="name" required minlength="4" pattern="([A-z\s]){2,}" class="form-control" placeholder="Nama Lengkap">
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-user"></span>
                          </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" ref="email" required v-model="form.email" placeholder="Email">
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                          </div>
                        </div>
                    </div>
                    <div class="input-group mt-3">
                        <input type="tel" class="form-control" ref="tel" required v-model="form.tel" placeholder="No Telepon">
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-phone"></span>
                          </div>
                        </div>
                    </div>
                    <div class="input-group mt-3">
                        <input type="password" class="form-control" ref="pass" minlength="8" required v-model="form.pass" placeholder="Sandi">
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                          </div>
                        </div>
                    </div>
                    <div class="input-group mt-3">
                        <input type="password" class="form-control" ref="pass2" required v-model="form.pass2" placeholder="Ulangi Sandi">
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                          </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-8">
                          <div class="icheck-primary">
                            <input type="checkbox" id="agreeTerms" name="terms" value="agree">
                            <label for="agreeTerms">
                             &nbsp; &nbsp; Saya setuju
                            </label>
                          </div>
                        </div>
                        <!-- /.col -->
                    </div>
                    <div class="social-auth-links text-center mb-3">
                        <button type="submit" class="btn btn-block btn-primary btn-lg" :disabled="loading">
                            <span v-if="loading" style="margin-bottom: 2px;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" Loading...":"Submit data" }}
                        </button>
                    </div>
                    <div class="row">
                        <div class="col-6"><router-link to="/login">Login</router-link></div>
                        <div class="col-6 text-right"><router-link to="/forgot-password">Lupa Password</router-link></div>
                    </div>
                    <div class="text-center mt-5 mb-5">
                        <p>All Right Reserved</p>
                        <p>TARUNA CENDEKIA © 2021</p>
                    </div>
                </div>
                <!-- /.login-card-body -->
            </div>
        </form>
    </div>
    <!-- /.login-box -->
</template>
<script>
import { auth } from '@/libs/hxcore';
import $ from 'jquery';
//import '@/assets/login.css';
export default {
    name: 'Registrasi',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            formTitle: 'Registrasi',
            showMessage: false,
            textMessage: '',
            alertStatus: '',
            loading: false,
            form: {
                name: '',
                email: '',
                tel: '',
                pass: '',
                pass2: '',
                agree: '',
            },
            loginForm: {
                username: '',
                password: ''
            }
        }
    },
    computed: {
    },
    created: function() {
        $('body').addClass('register-page');
        $('body').removeAttr('style');
    },
    mounted() {
        
    },
    beforeDestroy() {
        $('body').removeClass('register-page');
    },
    methods: {
        resetForm: function() {
            for (const key in this.$refs) {
              this.$refs[key].setAttribute('title', '');
              this.$refs[key].classList.remove('is-invalid');
            }
        },
        submitForm: function(e) {
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                this.loading = true;
                this.failed = false;
                fetch(window.$apiUrl + '/users/register', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: data
                    })
                    .then(res => {
                        this.loading = false;
                        if (res.status === 201) {

                        } else if (res.status === 400) {
                            //let errorResponse = await res.json();
                            //this.errors.push(errorResponse.error);
                        }
                        return res.json();
                    })
                    .then(js => {
                        if (js.status) {
                            // console.log(js)
                            // auth.login(js);
                            // this.$router.push('/pending-registration');
                            this.loginForm.username = this.form.email
                            this.loginForm.password = this.form.pass
                            this.login()
                            // this.$router.push('/');
                        } else {
                            this.failed = true;
                            this.failedMsg = js.message;
                        }
                        console.log(js)
                    });
            e.preventDefault();
        },
        login: function() {
            var data = Object.keys(this.loginForm).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.loginForm[key])).join('&')
            this.loading = true;
            this.failed = false;
            fetch(window.$apiUrl + '/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: data
                })
                .then(res => {
                    this.loading = false;
                    if (res.status === 201) {

                    } else if (res.status === 400) {
                        //let errorResponse = await res.json();
                        //this.errors.push(errorResponse.error);
                    }
                    return res.json();
                })
                .then(js => {
                    if (js.status) {
                        auth.login(js);
                        console.log(js)
                        this.$router.push('/');
                    } else {
                        this.failed = true;
                        this.failedMsg = js.message;
                    }
                    console.log(js)
                });
        }
    }
}
$(function() {
    $('[data-toggle="password"]').each(function() {
        var input = $(this);
        var eye_btn = $(this).parent().find('.input-group-text');
        eye_btn.css('cursor', 'pointer').addClass('input-password-hide');
        eye_btn.on('click', function() {
            if (eye_btn.hasClass('input-password-hide')) {
                eye_btn.removeClass('input-password-hide').addClass('input-password-show');
                eye_btn.find('.fa').removeClass('fa-eye').addClass('fa-eye-slash')
                input.attr('type', 'text');
            } else {
                eye_btn.removeClass('input-password-show').addClass('input-password-hide');
                eye_btn.find('.fa').removeClass('fa-eye-slash').addClass('fa-eye')
                input.attr('type', 'password');
            }
        });
    });

    setInterval(function() {
        $('[data-slide="next"]').click();
    }, 5000);
});
</script>
<style lang="scss">
.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    background: linear-gradient(90deg, #6190E8 0%, #A7BFE8 100%);
@media (max-width: 576px){
    .login-box, .register-box {
    margin-top: 20px;
    width: 360px; 
}
}

    .login-box {
        min-height: 140vh;
        position: relative;

        .form-control {
            height: calc(2rem + 2px);
            border:0;
        }

        .card {

            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
        }

        .login-card-body,
        .register-card-body {
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
            background-color: #F1F1FA;
            .input-group .input-group-text
            {
                background-color:#fff;
                border:0;
            }
        }

        form {
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    }
}
ion-grid {
  min-height: 100%;
 }
</style>
